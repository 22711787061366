function projectSort (a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function viewSort (a, b) {
    if (a.isFavorite && !b.isFavorite) {
        return -1;
    }
    if (b.isFavorite && !a.isFavorite) {
        return 1;
    }

    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function generateProjectTree (item, data) {
    if (item.isView) {
        return item;
    }
    const childViews = item.children || [];
    const childProjects = data
        .filter(d => d.parentProjectId === item.id) // Only views/projects that are in this project
        .map(d => generateProjectTree(d, data)) // Generate subtree
        .filter(d => d.isView || d.children); // Only views and projects that have children
    const children = [...childViews, ...childProjects].sort(viewSort);
    return children.length ? { ...item, children } : item;
}


export {
    projectSort,
    viewSort,
    generateProjectTree
};