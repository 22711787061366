import * as api from '../../services/api.service';
import * as util from '../../services/util.service';

const state = () => ({
    loadingViews: false,
    view: null,
    views: [],
    viewsPagination: {
        pageNumber: 1,
        pageSize: 99
    },
});

const mutations = {
    loadingViews (state, isLoading) {
        state.loadingViews = isLoading;
    },
    view (state, view) {
        state.view = view;
    },
    views (state, views) {
        state.views = views;
        state.views.sort(util.viewSort);
    },
    viewsPagination (state, pagination) {
        state.viewsPagination = {
            pageNumber: parseInt(pagination.pageNumber, 10),
            pageSize: parseInt(pagination.pageSize, 10),
            totalAvailable: parseInt(pagination.totalAvailable, 10)
        };
    },
};

const getters = {};

const actions = {
    async getRequestedView ({ }, {url}) {
        let name = url.split('/').pop().split('?').shift();
        if (!name) {
            return;
        }
        return await api.getView({name});
    },
    loadViews ({commit, state}, {tags}) {
        commit('loadingViews', true);
        return api.getViews({tags}, state.viewsPagination.pageNumber, state.viewsPagination.pageSize).then(({views, pagination}) => {
            let newViews = [...state.views, ...views];
            for (let i = newViews.length - 1; i >= 0; i--) {
                let otherInstanceIndex = newViews.findIndex(function(el) {
                    return el.id === newViews[i].id;
                });
                if (otherInstanceIndex !== i && otherInstanceIndex !== -1) {
                    newViews.splice(i, 1);
                }
            }
            commit('views', newViews);
            commit('viewsPagination', pagination);
            commit('loadingViews', false);
        });
    },
    addFavorite ({ }, view) {
        api.addViewToFavorites(view.id);
    },
    removeFavorite ({ }, view) {
        api.deleteViewFromFavorites(view.id);
    },
    setView ({commit, rootState}, view) {
        commit('view', view);
        const currentPath = window.location.pathname + window.location.search;
        const qs = window.location.search;
        const path = `/p/t/${rootState.about.tableau_server.site_name}/views/${view.contentUrl}`;
        if (currentPath !== path + qs) {
            window.history.pushState({view}, '', path);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};